import LandingPage from "@/views/mainHomePages/LandingPage.vue";
import LoginPage from "@/views/mainHomePages/LoginPage.vue";
import { Auth } from "aws-amplify";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: LandingPage,
    meta: {
      requiredAuth: true,
      title: "HelloWRK | Home",
    },
    children: [
      {
        path: "/Teams",
        name: "Teams",
        component: () => import("@/views/childHomePages/Teams.vue"),
        meta: {
          requiredAuth: true,
          title: "Teams | HelloWRK - BMS",
        },
      },
      {
        path: "/New-team-requests",
        name: "New-team-requests",
        component: () => import("@/views/childHomePages/NewTeamRequests.vue"),
        meta: {
          requiredAuth: true,
          title: "New Team Requests | HelloWRK - BMS",
        },
      },
      {
        path: "/Modules",
        name: "Modules",
        component: () => import("@/views/childHomePages/Modules.vue"),
        meta: {
          requiredAuth: true,
          title: "Modules | HelloWRK - BMS",
        },
      },
      {
        path: "/Packages",
        name: "Packages",
        component: () => import("@/views/childHomePages/Packages.vue"),
        meta: {
          requiredAuth: true,
          title: "Packages | HelloWRK",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const defaultTitle = "HelloWRK | BMS";
  document.title = to.meta.title || defaultTitle;

  const requiresAuth = to.matched.some((record) => record.meta.requiredAuth);

  if (requiresAuth) {
    try {
      await Auth.currentAuthenticatedUser();
      next();
    } catch (error) {
      console.error("Authentication required:", error);
      alert("You need to be logged in to access this page. Redirecting to login...");
      next({ name: "LoginPage", query: { redirect: to.fullPath }, replace: true });
    }
  } else {
    next();
  }
});

export default router;

