import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

export default createStore({
  state: {
    EmailID: "",
    get_current_user_details: {},
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details(state) {
      return state.get_current_user_details;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, userDetails) {
      state.get_current_user_details = userDetails;
    },
  },
  plugins: [new VuexPersistence().plugin],
});
