<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.gradient1 {
  background: hsla(14, 93%, 53%, 1);
  background: linear-gradient(90deg, hsla(14, 93%, 53%, 1) 0%, hsla(30, 84%, 58%, 1) 100%);
}

.borderRadiusLoginright {
  border-radius: 0 25px 25px 0;
  border: 0.5px solid #f47f40;
}

.borderRadiusLoginleft {
  border-radius: 25px 0 0 25px;
}

.v-input .v-label {
  font-size: 12px;
}

.PrimaryFontColor {
  color: #424242;
  font-size: 20px;
}

.heading3 {
  font-size: 20px;
  color: #1c1c1c;
}

.field_height.v-text-field .v-input__control .v-input__slot {
  min-height: 12px !important;
}

.field_label_size.v-input .v-label {
  font-size: 12px;
}

.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}

.borderRadius {
  border-radius: 5px !important;
}

.FontSize {
  font-size: 12px !important;
}

.GreenHeader {
  color: green;
}

.RedHeader {
  color: red !important;
}

.maxWidth {
  max-width: 200px !important;
}

.maxWidthLarge {
  max-width: 300px !important;
}

.maxWidthSmall {
  max-width: 120px !important;
}

.editorHeight {
  height: 450px !important;
}

.editorHeightSM {
  height: 50px !important;
}

.v-label {
  margin-top: -4px;
}

.tableClass table {
  border-collapse: separate !important;
  border-spacing: 0 4px !important;
}

.tableClass th {
  height: 20px !important;
  padding: 1px !important;
  font-size: 15px !important;
  text-align: start !important;
}

.tableClass td {
  padding: 1px !important;
  font-size: 12px !important;
}

.tableClass th {
  background-color: #f8c287 !important;
  color: #323b3f !important;
  padding: 5px !important;
  border-bottom: 1px solid #09142c !important;
  border-left: none;
}

.tableClass td {
  padding: 0px;
  height: 25px !important;
  font-size: 12px !important;
  border-left: none !important;
}

.tableClass td:first-child {
  border-radius: 6px 0px 0px 6px;
}

.tableClass td:last-child {
  border-radius: 0px 6px 6px 0px !important;
}

.v-data-table__selected {
  color: #f47f40;
}

.v-data-table__selected .theme--light.v-icon {
  color: #f47f40;
}

.scroll .v-data-table__wrapper::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
  background-color: #ffffff;
  color: red;
}

.scroll .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #f47f40 !important;
  border: solid 3px #e6e6e6 !important;
  border-radius: 7px !important;
}

.scroll .v-data-table__wrapper::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
  background-color: #ffffff;
  color: red;
}

.scroll .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #0f99da !important;
  border: solid 3px #e6e6e6 !important;
  border-radius: 7px !important;
}

.select .v-select-list .v-list-item {
  min-height: 0 !important;
  min-height: 48px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}

.action-item :hover {
  cursor: pointer;
}
</style>
