import { GetCurrentUserDetails } from "@/graphql/queries.js";
import { API, Auth, graphqlOperation } from "aws-amplify";
export const GetCurrentUserDetailsQuery = {
  data() {
    return {
      overlay: false,
      GetCurrentUserDetailsObject: {},
    };
  },
  methods: {
    async GetCurrentUserDetailsMethod() {
      try {
        this.GetCurrentUserDetailsObject = {};
        let result = await API.graphql(
          graphqlOperation(GetCurrentUserDetails, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );
        this.GetCurrentUserDetailsObject = JSON.parse(result.data.GetCurrentUserDetails).items;
        this.$store.commit("SET_CURRENTUSER_DETAILS", this.GetCurrentUserDetailsObject);
        if (this.$route.name != "Teams" && this.$route.name == "LandingPage") {
          this.$router.push("Teams");
        }

        this.overlay = false;
      } catch (error) {
        console.log("Error!", error);
        this.GetCurrentUserDetailsObject = {};
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Something Went Wrong!! ",
        };
        setTimeout(async () => {
          await Auth.signOut();
          this.$router.push("/");
        }, 2000);
      }
    },
  },
};
