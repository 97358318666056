<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-app-bar density="compact" color="white" elevation="1">
      <v-app-bar-nav-icon color="primary" class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-card class="elevation-0">
        <v-img src="@/assets/HelloWRK Hz Bk.jpg" width="140"></v-img>
      </v-card>
      <h2>BMS</h2>
      <v-spacer />
      <span>
        {{ $store.getters.get_user_email }}
      </span>
      <v-tooltip bottom color="red">
        <template v-slot:activator="{ props }">
          <v-btn @click="signOut" icon>
            <v-icon color="red" dark>mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
        <span class="text-white">Sign Out ?</span>
      </v-tooltip>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute width="190" color="secondary" dark>
      <div class="mt-2" v-for="(item, idx) in RouterList" :key="idx">
        <v-card class="elevation-0 cursorPointer mx-1" :color="item.active ? 'white text-black' : 'transparent'" @click="checkRouteMethod(item)">
          <v-card-text class="py-1 pl-0 FontSize font-weight-bold">
            <v-icon small class="mx-2" :color="item.active ? 'black' : 'white'"> {{ item.MenuICon }}</v-icon>
            {{ item.MenuName }}
          </v-card-text>
        </v-card>
      </div>
    </v-navigation-drawer>

    <router-view />
  </div>
</template>

<script>
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import RouterList from "@/jsonFiles/RouterList.json";
import { GetCurrentUserDetailsQuery } from "@/mixins/GetCurrentUser";
import { Auth } from "aws-amplify";
export default {
  mixins: [GetCurrentUserDetailsQuery],
  components: { Snackbar, Overlay },
  data: () => ({
    drawer: true,
    overlay: true,
    RouterList: [],
    SnackBarComponent: {},
  }),
  async mounted() {
    await this.GetCurrentUserDetailsMethod();
    this.RouterList = [];
    for (let i = 0; i < RouterList.length; i++) {
      this.RouterList.push({ ...RouterList[i] });
    }
    this.RouterList.forEach((routerItem) => {
      routerItem.active = routerItem.MenuRoute === this.$route.name;
    });

    this.$forceUpdate();
  },
  watch: {
    $route(val) {
      this.RouterList.forEach((routerItem) => {
        routerItem.active = routerItem.MenuRoute === val.name;
      });
    },
  },
  methods: {
    async signOut() {
      this.overlay = true;
      await Auth.signOut();
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "blue",
        Top: true,
        SnackbarText: "Logged out",
      };
      this.overlay = false;
      setTimeout(() => {
        this.$router.push("/");
      }, 500);
    },
    checkRouteMethod(item) {
      if (this.$router && this.$route && this.$route.name !== item.MenuRoute && !item.has_child_routes) {
        this.RouterList.forEach((routerItem) => {
          routerItem.active = routerItem.MenuRoute === item.MenuRoute;
        });
        if (this.$route.name !== item.MenuRoute) {
          this.$router.push(item.MenuRoute);
        }
      }
    },
  },
  components: { Snackbar, Overlay },
};
</script>
