<template>
  <v-overlay class="align-center justify-center" :model-value="overlay" opacity="0.1">
    <div class="loader"></div>
    <div class="text-black text-center">Loading...</div>
  </v-overlay>
</template>
<script>
export default {
  props: {
    overlay: Boolean,
    count: Number,
  },
};
</script>
<style>
.loader {
  --r1: 154%;
  --r2: 68.5%;
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #f47f40 80%),
    radial-gradient(var(--r1) var(--r2) at bottom, #f47f40 79.5%, #0000 80%), radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #f47f40 80%),
    #ccc;
  background-size: 50% 220%;
  background-position: -100% 0%, 0% 0%, 100% 0%;
  background-repeat: no-repeat;
  animation: l9 2s infinite linear;
}
@keyframes l9 {
  33% {
    background-position: 0% 33%, 100% 33%, 200% 33%;
  }
  66% {
    background-position: -100% 66%, 0% 66%, 100% 66%;
  }
  100% {
    background-position: 0% 100%, 100% 100%, 200% 100%;
  }
}
</style>
