// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#f47f40", // #E53935
          secondary: "#343f52", // #FFCDD2

          redChipColor: "#f58488",
          greenChipColor: "#c1fcac",
        },
      },
    },
  },
});
